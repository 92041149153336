/**
 * Formats a date value to be used in an input field
 * @param dateValue
 */
export const formatDateForInput = (dateValue: string | Date) => {
  if (!dateValue) return '';

  if (typeof dateValue === 'string') {
    // Return the string as-is
    return dateValue;
  } else {
    // Subtract one day from the date
    const date = new Date(dateValue);

    // Format the date back to 'yyyy-MM-dd'
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
};

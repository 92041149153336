import {addWeeks, isAfter} from 'date-fns';
import {IEmployee, IFirestoreEmployee} from "../data/interfaces";

/**
 * Determines if the user can edit an event based on their role and the event's start date.
 * @param startDate
 * @param employeeData
 */
export const userCanEdit = (
  startDate: Date | string,
  employeeData: IEmployee | null
): boolean => {

  //convert startDate to Date object if it is a string
  startDate = typeof startDate === 'string' ? new Date(startDate) : startDate;

  //determine user role
  const role = determineUserRole(employeeData);

  if (role === 'fullEdit') return true;
  if (role === 'restricted') {
    const twoWeeksFromToday = addWeeks(new Date(), 2);
    return isAfter(startDate, twoWeeksFromToday);
  }
  return false; // Read-only access for everyone else
};

export const determineUserRole = (employeeData: IFirestoreEmployee | null): 'readOnly' | 'fullEdit' | 'restricted' => {
  if (!employeeData || !employeeData.departmentString || !employeeData.seniorityString) {
    return 'readOnly'; // Default to readOnly if employee data or required fields are null or undefined
  }

  const department = employeeData.departmentString?.toLowerCase();
  const seniority = employeeData.seniorityString?.toLowerCase();

  if (department.includes("installation") && (seniority.includes("red") || seniority.includes("green") || seniority.includes("yellow"))) {
    return 'fullEdit'; // Full access
  }
  if (
    department.includes("customer support")) {
    return 'fullEdit'; // Full access
  }
  if (department.includes("software")) {
    return 'fullEdit'; // Full access
  }

  if (department.includes("sales")) {
    if (seniority.includes("red") || seniority.includes("green") || seniority.includes("blue")) {
      return 'fullEdit'; // Full access
    }
    return 'restricted'; // Restricted access (edit only events starting after two weeks from today)
  }

  return 'readOnly'; // Read-only access
};

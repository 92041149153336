import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin, {Draggable} from "@fullcalendar/interaction";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {addWeeks, format, isValid, parseISO, startOfWeek} from 'date-fns';
import {fetchEventsForRange} from '../data/fetchEvents';
import {Department, IConflictCheckResponse, IEmployee, IEvent} from '../data/interfaces';
import {EventClickArg} from '@fullcalendar/core';
import EventModal from "./EventModal";
import {useTheme} from "./ThemeContext";
import {deleteDoc, doc} from 'firebase/firestore';
import {db} from '../firebaseConfig';
import {signOut} from '../utils/authUtils';
import {adjustEndDateForFirestore, augmentEventForCalendar} from "../utils/augmentEventForCalendar";
import {formatDateForInput} from "../utils/formatDateForInput";
import {EventImpl} from "@fullcalendar/core/internal";
import {useUser} from '../context/userContext';
import {userCanEdit} from "../utils/permissions";
import {saveEvent} from "../data/saveEvent";
import {getEmployeeFromUid} from "../data/getEmployeeFromUid";
import {fetchAvailableCrews} from "../data/fetchAvailableCrews";
import Spinner from "./Spinner";
import {useNavigate} from 'react-router-dom';
import Header from "./Header";

interface CalendarProps {
  isCollapsed: boolean;
  toggleSidebar: () => void;
  currentDepartment: Department;
  setCurrentDepartment: (department: Department) => void;
  initialDate?: string;
  unscheduledTravelersCount: number;
  selectedTravelerId?: string;
  setSelectedTravelerId?: (id: string | undefined) => void;
}

interface Resource {
  id: string;
  title: string;
}

/**
 * Generates an array of week start dates based on the current date.
 *
 * @param {Date} currentDate - The reference date.
 * @returns {string[]} - An array of week start dates in 'yyyy-MM-dd' format.
 */
const getWeeksToLoad = (currentDate: Date): string[] => {
  const weeks: string[] = [];
  const currentWeekStart = startOfWeek(currentDate);

  // Previous 2 weeks
  for (let i = -2; i < 0; i++) {
    const weekStart = format(addWeeks(currentWeekStart, i), 'yyyy-MM-dd');
    weeks.push(weekStart);
  }

  // Current week and next 3 weeks
  for (let i = 0; i < 4; i++) {
    const weekStart = format(addWeeks(currentWeekStart, i), 'yyyy-MM-dd');
    weeks.push(weekStart);
  }

  return weeks;
};


/**
 * Constructs the start and end dates for a given week.
 *
 * @param {string} weekStart - The start date of the week in 'yyyy-MM-dd' format.
 * @returns {{ start: Date; end: Date }} - The start and end Date objects for the week.
 */
const getWeekDateRange = (weekStart: string): { start: Date; end: Date } => {
  const start = parseISO(weekStart);
  const end = addWeeks(start, 1);
  end.setDate(end.getDate() - 1); // End of the week (Sunday)
  return {start, end};
};

const Calendar: React.FC<CalendarProps> = ({
                                             isCollapsed,
                                             toggleSidebar,
                                             currentDepartment,
                                             initialDate,
                                             unscheduledTravelersCount,
                                             selectedTravelerId,
                                             setSelectedTravelerId,
                                           }) => {
  const {user} = useUser(); // Get authenticated user
  const navigate = useNavigate();
  const {theme, toggleTheme} = useTheme();

  // Parse and validate the initial date
  const parsedDate = initialDate ? parseISO(initialDate) : new Date();
  const validDate = isValid(parsedDate) ? parsedDate : new Date();

  // State variables
  const [currentDate, setCurrentDate] = useState<Date>(validDate);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
  const [availableCrews, setAvailableCrews] = useState<IEmployee[]>([]);
  const [jumpToDate, setJumpToDate] = useState<string>('');
  const [currentUserEmployeeData, setCurrentUserEmployeeData] = useState<IEmployee | null>(null);
  const formattedDate = format(currentDate, 'yyyy-MM-dd');

  // Refs for FullCalendar instances
  const calendarRef1 = useRef<FullCalendar | null>(null);
  const calendarRef2 = useRef<FullCalendar | null>(null);

  // Reference for debouncing
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Reference to store cached events to avoid redundant fetches
  const eventCache = useRef<{
    [cacheKey: string]: {
      events: IEvent[];
      unsubscribe?: () => void;
    };
  }>({});

  // State to hold the events that have been loaded and are ready to be displayed
  const [loadedEvents, setLoadedEvents] = useState<IEvent[]>([]);

  // Effect to open modal if a traveler is selected
  useEffect(() => {
    if (selectedTravelerId) {
      setIsModalOpen(true);
    }
  }, [selectedTravelerId]);

  // Fetch current user employee data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      const data = await getEmployeeFromUid(user.uid);
      setCurrentUserEmployeeData(data ?? null);
    }

    fetchUserData();
  }, [user]);

  /**
   * Filters the given events based on the current department.
   * Memoized to prevent unnecessary recalculations.
   */
  const filterEvents = useCallback((events: IEvent[]) =>
      events.filter((event: IEvent) => event.department === currentDepartment || event.department === 'all'),
    [currentDepartment]
  );

  /**
   * Updates the URL with the new date.
   * Memoized to prevent unnecessary re-creations.
   */
  const updateURLDate = useCallback((newDate: Date) => {
    const formattedDate = format(newDate, 'yyyy-MM-dd');
    const departmentPath = currentDepartment === 'Customer Support' ? 'customer-support' : 'installation';
    navigate(`/${departmentPath}/${formattedDate}`, {replace: true});
  }, [currentDepartment, navigate]);

  /**
   * Handles the jump to date functionality.
   */
  const handleJumpToDate = useCallback(() => {
    if (jumpToDate) {
      const newDate = parseISO(jumpToDate);
      if (isValid(newDate)) {
        // Update the URL, which will trigger the calendar to update via useEffect
        updateURLDate(newDate);
      } else {
        // Handle invalid date input
        alert('Please enter a valid date.');
      }
    } else {
      // If the date is cleared, revert to the current date
      updateURLDate(new Date());
    }
  }, [jumpToDate, updateURLDate]);

  /**
   * Checks if a particular week is in the loading state.
   *
   * @param {Date} currentDate - The reference date.
   * @param {1 | 2} weekIndex - The index of the week (1 or 2).
   * @returns {boolean} - True if the week is loading, false otherwise.
   */
  const isWeekLoading = useCallback((currentDate: Date, weekIndex: 1 | 2): boolean => {
    const weekStartDate = startOfWeek(currentDate);
    const weekStartString = weekIndex === 1 ? format(weekStartDate, 'yyyy-MM-dd') : format(addWeeks(weekStartDate, 1), 'yyyy-MM-dd');
    const cacheKey = `${weekStartString}_${currentDepartment}`;
    return !(cacheKey in eventCache.current);
  }, [currentDepartment]);

  /**
   * Effect hook to fetch and cache events whenever dependencies change.
   */
  useEffect(() => {
    const weeksToLoad = getWeeksToLoad(currentDate);

    // Cleanup cache: unsubscribe and remove entries that are no longer needed
    Object.keys(eventCache.current).forEach((cacheKey) => {
      const [weekStart, department] = cacheKey.split('_');
      if (!weeksToLoad.includes(weekStart) || department !== currentDepartment) {
        const unsubscribe = eventCache.current[cacheKey].unsubscribe;
        if (unsubscribe) {
          unsubscribe();
        }
        delete eventCache.current[cacheKey];
      }
    });

    const DEBOUNCE_DELAY = 300;

    // Clear any existing timeout to prevent multiple fetches
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set up a new timeout to fetch and cache events after the debounce delay
    debounceTimeoutRef.current = setTimeout(() => {
      const fetchAndCacheEvents = async () => {
        await Promise.all(
          weeksToLoad.map(async (weekStart) => {
            const cacheKey = `${weekStart}_${currentDepartment}`;
            if (!(cacheKey in eventCache.current)) {
              // Initialize cache entry
              eventCache.current[cacheKey] = {
                events: [],
              };

              const {start: fetchStartDate, end: fetchEndDate} = getWeekDateRange(weekStart);

              const onEventsUpdate = (events: IEvent[]) => {
                if (!eventCache.current[cacheKey]) return;

                // Update the cache
                eventCache.current[cacheKey].events = events;

                // Aggregate all loaded events from the cache to update the state
                const allLoadedEvents = Object.values(eventCache.current).flatMap(
                  (cacheEntry) => cacheEntry.events
                );
                setLoadedEvents(allLoadedEvents);
              };

              try {
                // Fetch events for this week and store unsubscribe function
                eventCache.current[cacheKey].unsubscribe = await fetchEventsForRange(
                  fetchStartDate,
                  fetchEndDate,
                  currentDepartment,
                  onEventsUpdate
                );
              } catch (error) {
                console.error('Error fetching events:', error);
                // Optionally, handle the error by updating state or notifying the user
              }
            }
          })
        );

        // After fetching, aggregate all loaded events from the cache to update the state
        const allLoadedEvents = Object.values(eventCache.current).flatMap(
          (cacheEntry) => cacheEntry.events
        );
        setLoadedEvents(allLoadedEvents);
      };

      // Initiate the fetch and cache process
      fetchAndCacheEvents();
    }, DEBOUNCE_DELAY);

    // Cleanup function to clear the timeout
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [currentDate, currentDepartment]);

  /**
   * Effect to unsubscribe from all listeners when component unmounts
   */
  useEffect(() => {
    return () => {
      Object.values(eventCache.current).forEach((cacheEntry) => {
        if (cacheEntry.unsubscribe) {
          cacheEntry.unsubscribe();
        }
      });
      eventCache.current = {};
    };
  }, []);

  /**
   * Effect to load available crews when the current department changes
   */
  useEffect(() => {
    const loadAvailableCrews = async () => {
      const crews = await fetchAvailableCrews(currentDepartment);
      setAvailableCrews(crews);
    };

    loadAvailableCrews();
  }, [currentDepartment]);

  /**
   * Memoized computation of combined resources to prevent unnecessary recalculations
   */
  const combinedResources = useMemo(() => {
    const firstDayShown = startOfWeek(currentDate);
    const lastDayShown = addWeeks(firstDayShown, 1);
    lastDayShown.setDate(lastDayShown.getDate() + 6);

    const today = new Date();

    function dedupeAndSortResources(resources: Resource[]): Resource[] {
      const uniqueResources = resources.reduce((acc: Resource[], resource) => {
        if (!acc.some(existing => existing.id === resource.id)) {
          acc.push(resource);
        }
        return acc;
      }, []);

      return uniqueResources.sort((a, b) => {
        if (a.id === 'unassigned') return 1;
        if (b.id === 'unassigned') return -1;
        return a.id.localeCompare(b.id);
      });
    }

    const eventResources = filterEvents(loadedEvents).reduce((acc: Resource[], event) => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end ?? event.start);

      const isEventInRange = (
        (eventStart >= firstDayShown && eventStart <= lastDayShown) ||
        (eventEnd >= firstDayShown && eventEnd <= lastDayShown) ||
        (eventStart < firstDayShown && eventEnd > lastDayShown)
      );

      if (isEventInRange && event.resourceId && !acc.some(resource => resource.id === event.resourceId)) {
        acc.push({
          id: event.resourceId,
          title: event.resourceId,
        });
      }

      return acc;
    }, []);

    const crewResources = availableCrews.map(crew => ({
      id: crew.preferredName,
      title: crew.preferredName,
    }));

    const resources = currentDepartment === 'Installation' && (today <= lastDayShown || firstDayShown > today)
      ? [...eventResources, ...crewResources, {id: 'unassigned', title: 'Unassigned'}]
      : eventResources;

    return dedupeAndSortResources(resources);
  }, [loadedEvents, availableCrews, currentDate, currentDepartment, filterEvents]);

  /**
   * Handles the save event function.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleSaveFunction = useCallback(async (eventDetails: IEvent) => {
    const existingEventIndex = loadedEvents.findIndex(loadedEvent => loadedEvent.id === eventDetails.id);

    if (existingEventIndex !== -1) {
      // Update the existing event in the state
      setLoadedEvents(prevEvents => {
        const updatedEvents = [...prevEvents];
        updatedEvents[existingEventIndex] = eventDetails;
        return updatedEvents;
      });
    } else {
      // Add the new event to the state
      setLoadedEvents(prevEvents => [...prevEvents, eventDetails]);
    }

    await saveEvent(eventDetails, user);
  }, [loadedEvents, user]);

  /**
   * Handles the event click event.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleEventClick = useCallback((info: EventClickArg) => {
    const event: EventImpl = info.event;
    const selectedEvent = loadedEvents.find(loadedEvent => event.id === loadedEvent.id);
    if (selectedEvent) {
      setSelectedEvent(selectedEvent);
      setIsModalOpen(true);
    }
  }, [loadedEvents]);

  /**
   * Handles the event drag event.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleEventDrag = useCallback(async (info: any) => {
    const event = info.event as EventImpl;
    const selectedEvent = loadedEvents.find(loadedEvent => event.id === loadedEvent.id);

    if (!selectedEvent) return;

    const eventStart = event.start ? new Date(event.start) : null;
    const eventEnd = event.end ? new Date(event.end) : eventStart; // Use event.start if event.end is not available

    const canEdit = currentUserEmployeeData && eventStart && eventEnd
      ? userCanEdit(eventStart, currentUserEmployeeData)
      : false;

    if (!canEdit) {
      info.revert(); // Revert the event to its original position
      alert("You do not have permission to move this event.");
      return;
    }

    const newResource = event.getResources()[0];
    const newResourceId = newResource?.id;
    const isTeamEvent = selectedEvent.eventType === 'Holiday' || selectedEvent.eventType === 'Team Meeting';

    const newCrew = !isTeamEvent && newResourceId
      ? availableCrews.find(crew => crew.preferredName === newResourceId) ?? null
      : selectedEvent.crew;

    const modifiedEvent: IEvent = {
      ...selectedEvent,
      start: event.start ? formatDateForInput(event.start) : selectedEvent.start,
      end: event.end ? adjustEndDateForFirestore(event.end) : selectedEvent.end,
      resourceId: newResourceId ?? selectedEvent.resourceId,
      crew: isTeamEvent ? null : newCrew,
    };

    // Revert the resource change if it's a team event
    if (isTeamEvent && modifiedEvent.crew?.preferredName !== newResourceId) {
      const resourceId = modifiedEvent.crew?.preferredName ?? 'unassigned';
      event.setResources([resourceId]);
    }

    await handleSaveFunction(modifiedEvent);
  }, [loadedEvents, currentUserEmployeeData, availableCrews, handleSaveFunction]);

  /**
   * Handles the event delete event.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleEventDelete = useCallback(async (eventDetails: IEvent) => {
    try {
      const eventRef = doc(db, '_events', eventDetails.id);
      await deleteDoc(eventRef);
      console.log('Event deleted from Firebase:', eventDetails);

      // Update local state to remove the deleted event
      setLoadedEvents((prevEvents) => prevEvents.filter(event => event.id !== eventDetails.id));
    } catch (error) {
      console.error('Error deleting event from Firebase:', error);
    }
  }, []);

  /**
   * Handles the close modal event.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    if (setSelectedTravelerId) {
      setSelectedTravelerId(undefined);
    }
  }, [setSelectedTravelerId]);

  /**
   * Handles the duplicate event.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleDuplicate = useCallback(async (duplicatedEvent: IEvent) => {
    await handleSaveFunction(duplicatedEvent);
  }, [handleSaveFunction]);


  /**
   * Handles the calendar navigation.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleCalendarNavigation = useCallback((action: 'prev' | 'next') => {
    if (calendarRef1.current && calendarRef2.current) {
      const calendarApi1 = calendarRef1.current.getApi();
      const calendarApi2 = calendarRef2.current.getApi();

      // Perform the action (prev or next)
      calendarApi1[action]();
      calendarApi2[action]();

      // Update the current date state
      const newCurrentDate = calendarApi1.getDate();
      setCurrentDate(newCurrentDate);

      // Update the URL with the new date
      updateURLDate(newCurrentDate);
    }
  }, [updateURLDate]);

  const handlePrev = useCallback(() => handleCalendarNavigation('prev'), [handleCalendarNavigation]);
  const handleNext = useCallback(() => handleCalendarNavigation('next'), [handleCalendarNavigation]);

  /**
   * Handles the today button click.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleToday = useCallback(() => {
    const today = new Date();
    const nextWeek = addWeeks(today, 1);
    if (calendarRef1.current && calendarRef2.current) {
      const calendarApi1 = calendarRef1.current.getApi();
      const calendarApi2 = calendarRef2.current.getApi();
      calendarApi1.gotoDate(today);
      calendarApi2.gotoDate(nextWeek);
      setCurrentDate(today);

      // Update the URL with today's date
      updateURLDate(today);
    }
  }, [updateURLDate]);

  /**
   * Handles the new event click event.
   * Memoized to prevent unnecessary re-creations.
   */
  const handleNewEventClick = useCallback(() => {
    setSelectedEvent(null); // Set to a new event object
    setIsModalOpen(true);
  }, []);

  /**
   * Checks for conflicts between the given event and the loaded events.
   * Memoized to prevent unnecessary re-creations.
   */
  const checkForConflicts = useCallback((event: IEvent): IConflictCheckResponse => {
    const localEvent = loadedEvents.find(loadedEvent => loadedEvent.id === event.id) || null;

    if (!localEvent) return {safeToSave: true};

    if (event.lastModifiedBy && localEvent.lastModifiedBy) {
      return {
        safeToSave: event.lastModifiedBy.time >= localEvent.lastModifiedBy.time,
        lastModifiedBy: localEvent.lastModifiedBy
      };
    } else {
      return {safeToSave: true};
    }
  }, [loadedEvents]);

  /**
   * Initializes draggable external events.
   */
  useEffect(() => {
    const externalEvents = document.getElementById('external-events');
    if (externalEvents) {
      new Draggable(externalEvents, {
        itemSelector: '.fc-event',
        eventData: function (eventEl: any) {
          let title = eventEl.getAttribute('data-event');
          title = title ? JSON.parse(title) : {};

          return {
            ...title,
            duration: {days: 1}
          };
        }
      });
    }
  }, []);

  /**
   * Effect to handle initial date setup and calendar navigation
   */
  useEffect(() => {
    if (initialDate) {
      const parsedDate = parseISO(initialDate);
      if (isValid(parsedDate)) {
        setCurrentDate(parsedDate);

        // Update the calendars to show the selected date and the following week
        if (calendarRef1.current && calendarRef2.current) {
          const calendarApi1 = calendarRef1.current.getApi();
          const calendarApi2 = calendarRef2.current.getApi();
          calendarApi1.gotoDate(parsedDate);
          calendarApi2.gotoDate(addWeeks(parsedDate, 1));
        }
      }
    }
  }, [initialDate]);

  /**
   * Memoized list of augmented events to prevent unnecessary recalculations.
   */
  const augmentedEvents = useMemo(() => {
    return filterEvents(loadedEvents).flatMap(event => {
      const augmentedEvent = augmentEventForCalendar(event);

      if (event.eventType === 'Holiday' || event.eventType === 'Team Meeting') {
        // Keep the original event as is for selection and editing
        const originalEvent = {...augmentedEvent};

        // Create a copy for the background event
        const backgroundEvent = {...augmentedEvent};
        backgroundEvent.id = `${backgroundEvent.id}_background`; // Ensure unique ID
        delete backgroundEvent.resourceId; // Remove resource assignment
        backgroundEvent.display = 'background'; // Set as background event

        return [originalEvent, backgroundEvent]; // Return both events
      } else {
        // For other events, proceed as normal
        augmentedEvent.resourceId = event.resourceId || 'unassigned';
        return augmentedEvent;
      }
    });
  }, [filterEvents, loadedEvents]);

  return (
    <div id="calendar-container">

      {/* Render the Header component and pass necessary props */}
      <Header
        toggleSidebar={toggleSidebar}
        isCollapsed={isCollapsed}
        handlePrev={handlePrev}
        handleToday={handleToday}
        handleNext={handleNext}
        jumpToDate={jumpToDate}
        setJumpToDate={setJumpToDate}
        handleJumpToDate={handleJumpToDate}
        handleNewEventClick={handleNewEventClick}
        unscheduledTravelersCount={unscheduledTravelersCount} // Example count, can be dynamic
        formattedDate={formattedDate}
        signOut={signOut}
        theme={theme}
        toggleTheme={toggleTheme}
      />

      {/* Main calendar view */}
      <div className="calendar-content overflow-auto vh-97">
        {/* Top Calendar */}
        <div id="top-calendar" className="position-relative mb-2" style={{minHeight: '35vh'}}>
          {isWeekLoading(currentDate, 1) && (
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-black bg-opacity-25"
              style={{zIndex: 100, minHeight: '30vh'}}
            >
              <Spinner/>
            </div>
          )}
          <FullCalendar
            ref={calendarRef1}
            plugins={[interactionPlugin, resourceTimelinePlugin]}
            droppable={true}
            editable={true}
            eventClick={handleEventClick}
            events={augmentedEvents}
            eventDrop={handleEventDrag}
            eventReceive={handleEventDrag}
            eventResize={handleEventDrag}
            height={"auto"}
            hiddenDays={[0]}
            initialView='resourceTimelineWeek'
            initialDate={format(startOfWeek(currentDate), 'yyyy-MM-dd')}
            resourceAreaHeaderContent="Crew"
            resourceAreaWidth="8%"
            resources={combinedResources} // Use combined resources for both weeks
            resourceOrder="sortOrder"
            selectable={true}
            schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
            slotDuration={{day: 1}}
            slotLabelFormat={{
              day: 'numeric',
              weekday: 'short'
            }}
            headerToolbar={{
              left: '',
              center: 'title',
              right: ''
            }}
            customButtons={{
              customPrev: {
                text: 'prevButton',
                click: handlePrev,
              },
              customNext: {
                text: 'nextButton',
                click: handleNext,
              },
              customToday: {
                text: 'Today',
                click: handleToday,
              },
            }}
          />
        </div>

        {/* Bottom Calendar */}
        <div id="bottom-calendar" className="position-relative" style={{minHeight: '35vh'}}>
          {isWeekLoading(currentDate, 2) && (
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-black bg-opacity-25"
              style={{zIndex: 100, minHeight: '30vh'}}
            >
              <Spinner/>
            </div>
          )}
          <FullCalendar
            ref={calendarRef2}
            plugins={[interactionPlugin, resourceTimelinePlugin]}
            initialView='resourceTimelineWeek'
            initialDate={format(addWeeks(currentDate, 1), 'yyyy-MM-dd')}
            droppable={true}
            editable={true}
            events={augmentedEvents}
            eventClick={handleEventClick}
            eventDrop={handleEventDrag}
            eventReceive={handleEventDrag}
            eventResize={handleEventDrag}
            resourceAreaHeaderContent="Crew"
            resourceAreaWidth="8%"
            resources={combinedResources}
            resourceOrder="sortOrder"
            selectable={true}
            schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
            hiddenDays={[0]}
            height={"auto"}
            slotDuration={{day: 1}}
            slotLabelFormat={{
              day: 'numeric',
              weekday: 'short'
            }}
            headerToolbar={{
              left: '',
              center: 'title',
              right: ''
            }}
            titleFormat={{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }}
          />
        </div>
      </div>

      {/* Event Modal */}
      <EventModal
        show={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleSave={handleSaveFunction}
        event={selectedEvent}
        department={currentDepartment}
        availableCrews={availableCrews}
        deleteEvent={handleEventDelete}
        handleDuplicate={handleDuplicate}
        checkForConflicts={checkForConflicts}
        employeeData={currentUserEmployeeData}
        travelerId={selectedTravelerId}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default Calendar;

import { IEmployee, IFirestoreUser } from "./interfaces";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import {getEmployeeDetailsByRef} from "./getEmployeeDetailsByRef";

/**
 * Retrieves an employee's details from Firestore using their UID.
 *
 * @param {string} uid - The UID of the employee.
 * @returns {Promise<IEmployee | null>} A promise that resolves to the employee's details or null if not found.
 */
export async function getEmployeeFromUid(uid: string): Promise<IEmployee | null> {
  try {
    // Create a reference to the user document in the "users" collection using the provided UID.
    const userRef = doc(db, "users", uid);

    // Fetch the user document from Firestore.
    const userDoc = await getDoc(userRef);

    // Check if the user document exists; if not, return null.
    if (!userDoc.exists()) {
      return null;
    }

    // Extract the user data and cast it to the IFirestoreUser interface.
    const userData = userDoc.data() as IFirestoreUser;

    // Use the getEmployeeDetails function to transform the Firestore employee data into an IEmployee object.
    return (await getEmployeeDetailsByRef(userData.employeeRef ?? undefined)) ?? null;
  } catch (error) {
    // Log any errors encountered during the process.
    console.error("Error fetching employee from Firestore:", error);
    return null;
  }
}

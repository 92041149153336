// Import necessary modules and types from React and Firebase
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebaseConfig'; // Import Firebase configuration
import { onAuthStateChanged, User } from 'firebase/auth'; // Import Firebase authentication functions and types

/**
 * Interface representing the value of the UserContext.
 */
interface UserContextValue {
    user: User | null; // The currently authenticated user or null if not authenticated
    loading: boolean;  // Indicates whether the authentication state is still being determined
}

/**
 * Context object to provide the user authentication state and loading status to child components.
 */
const UserContext = createContext<UserContextValue>({
    user: null,
    loading: true,
});

/**
 * Custom hook to access the UserContext.
 * Provides easy access to the user authentication state and loading status.
 *
 * @returns {UserContextValue} The current user and loading state.
 */
export const useUser = (): UserContextValue => useContext(UserContext);

/**
 * Props interface for the UserProvider component.
 */
interface UserProviderProps {
    children: React.ReactNode;
}

/**
 * UserProvider component that wraps its children with UserContext.Provider.
 * It listens to authentication state changes and updates the context accordingly.
 *
 * @param {UserProviderProps} props - The props containing children components.
 * @returns {React.ReactElement} The provider component wrapping the children.
 */
export const UserProvider = ({ children }: UserProviderProps): React.ReactElement => {
    // State to hold the current user object; initialized as null
    const [user, setUser] = useState<User | null>(null);
    // State to indicate whether the authentication state is loading; initialized as true
    const [loading, setLoading] = useState(true);

    // Effect hook to listen for authentication state changes when the component mounts
    useEffect(() => {
        // Subscribe to Firebase authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            // Update the user state with the current user object or null
            setUser(currentUser);
            // Set loading to false since authentication state has been determined
            setLoading(false);
        });

        // Cleanup function to unsubscribe from the listener when the component unmounts
        return () => unsubscribe();
    }, []);

    return (
      // Provide the user and loading state to child components via UserContext.Provider
      <UserContext.Provider value={{ user, loading }}>
          {children}
      </UserContext.Provider>
    );
};

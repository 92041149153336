import {IEvent, ISearchIndexData} from "../data/interfaces";
import {buildEventTitle} from "./buildEventTitle";

/**
 * Generates search index data from an event.
 *
 * @param {IEvent} event - The event object containing details to be indexed.
 * @returns {ISearchIndexData} The search index data including title, crew, and date range.
 */
export function getSearchIndexData(event: IEvent): ISearchIndexData {
  return {
    title: buildEventTitle(event),
    subtitle: buildSubtitle(event),
    dateRange: convertDateRangeToPrettyString(event.start, event.end),
  };
}

/**
 * Constructs the subtitle for the search index data based on event details.
 *
 * @param {IEvent} event - The event object containing details.
 * @returns {string} The constructed subtitle.
 */
function buildSubtitle(event: IEvent): string {
  if (event.department === "all") {
    return event.eventType || "unknown";
  }

  const crewName = event.eventType !== "Team Meeting"
    ? `${event.crew?.preferredName || "unassigned"} - `
    : "";

  return `${event.department} - ${crewName}${event.eventType}`;
}


//set the locale for date formatting
const locale = 'en-US';

/**
 * Helper function to format a single date with month, day, and year.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
function formatDateWithYear(date: Date): string {
  return date.toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
}

/**
 * Helper function to format a date's month and day without the year.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted month and day string.
 */
function formatMonthDay(date: Date): string {
  return date.toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric'
  });
}

/**
 * Converts start and end dates to a formatted string.
 *
 * @param {string | Date} start - The start date.
 * @param {string | Date | undefined} end - The end date.
 * @returns {string} The formatted date range string.
 */
function convertDateRangeToPrettyString(start: string | Date, end: string | Date | undefined): string {
  // Helper function to parse a date string to a Date object
  const parseDate = (date: string | Date): Date => {
    if (typeof date === 'string') {
      const [year, month, day] = date.split('-').map(Number);
      return new Date(year, month - 1, day);
    }
    return date;
  };

  // Parse the start and end dates
  const startDate: Date = parseDate(start);
  const endDate: Date | undefined = end ? parseDate(end) : undefined;

  // If end date is not provided, return the formatted start date
  if (!endDate || startDate.toISOString().split("T")[0] === endDate.toISOString().split("T")[0]) {
    return formatDateWithYear(startDate);
  }

  const sameYear = startDate.getFullYear() === endDate.getFullYear();
  const sameMonth = startDate.getMonth() === endDate.getMonth();

  if (sameYear) {
    if (sameMonth) {
      // Same month and year: "Month startDay-endDay, Year"
      const month = startDate.toLocaleDateString(locale, {month: 'long'});
      const startDay = startDate.getDate();
      const endDay = endDate.getDate();
      const year = startDate.getFullYear();
      return `${month} ${startDay}-${endDay}, ${year}`;
    } else {
      // Same year, different months: "Month1 startDay - Month2 endDay, Year"
      const startFormatted = formatMonthDay(startDate);
      const endFormatted = endDate.toLocaleDateString(locale, {
        month: 'long',
        day: 'numeric'
      });
      const year = startDate.getFullYear();
      return `${startFormatted} - ${endFormatted}, ${year}`;
    }
  } else {
    // Different years: "Month1 startDay, Year1 - Month2 endDay, Year2"
    const startFormatted = formatDateWithYear(startDate);
    const endFormatted = formatDateWithYear(endDate);
    return `${startFormatted} - ${endFormatted}`;
  }
}

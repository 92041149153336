import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";

/**
 * Firebase configuration object.
 */
const firebaseConfig = {
  apiKey: "AIzaSyAwAtOTFJySkTisfT0yciBCr5-UMwiprnI",
  authDomain: "pwi-apps.firebaseapp.com",
  databaseURL: "https://pwi-apps-default-rtdb.firebaseio.com",
  projectId: "pwi-apps",
  storageBucket: "pwi-apps.appspot.com",
  messagingSenderId: "277637891266",
  appId: "1:277637891266:web:96214cc666be2d62ac675b",
  measurementId: "G-JL3Z5C5X8V"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;


import {useEffect, useState} from 'react';
import {SearchResults} from 'algoliasearch-helper';

export const SearchEvents = (searchQuery: string) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);

  useEffect(() => {
    if (!searchQuery) {
      setSuggestions([]);
      return;
    }

    const {algoliasearch, instantsearch} = window;
    const searchClient = algoliasearch('1JVBWNUDCT', 'ee9a1fcf209119ef8c912244515d2300');
    const search = instantsearch({
      indexName: 'onsite-calendar-events',
      searchClient,
      searchFunction(helper) {
        helper.setQuery(searchQuery).search();
      },
      future: {preserveSharedStateOnUnmount: true},
    });

    const customHitsWidget = {
      $$type: 'custom.hitsWidget',
      init() {
      },
      render({results}: { results: SearchResults }) {
        setSuggestions(results.hits);
      },
      dispose() {
        setSuggestions([]);
      },
    };

    search.addWidgets([
      instantsearch.widgets.configure({
        hitsPerPage: 500,
      }),
      customHitsWidget,
    ]);

    search.start();

    return () => {
      search.dispose();
    };
  }, [searchQuery]);

  return suggestions.map(hit => {
    const dateRange = hit.searchIndexData.dateRange;
    const parts = dateRange ? dateRange.split('-') : [];
    const startMonthDay = parts[0]?.trim(); // "November 13"
    const endYear = parts[1]?.split(',')[1]?.trim(); // "2024"

    // Combine startMonthDay and endYear to create a valid date string
    const isValidDate = startMonthDay && endYear && !isNaN(Date.parse(`${startMonthDay}, ${endYear}`));
    const formattedDate = isValidDate ? new Date(`${startMonthDay}, ${endYear}`).toISOString().split('T')[0] : '';

    return { ...hit, formattedDate };
  });

};

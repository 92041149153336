import {
  and,
  collection,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  or,
  query,
  QuerySnapshot, setDoc,
  where
} from "firebase/firestore";
import app from '../firebaseConfig';
import {
  Department,
  EventDepartment,
  IEvent,
  IFirestoreEvent,
  IFirestoreTraveler,
  ITraveler
} from "./interfaces";
import {getEmployeeDetailsByRef} from "./getEmployeeDetailsByRef";
import {getSearchIndexData} from "../utils/getSearchIndexData";


// Initialize Firestore database instance
const db = getFirestore(app);


/**
 * Fetches events within a specified date range for a given department and sets up real-time updates.
 *
 * @param {Date} start - The start date of the range.
 * @param {Date} end - The end date of the range.
 * @param {Department} department - The department to filter events by.
 * @param {(events: IEvent[]) => void} onEventsUpdate - Callback function to handle updates to the list of events.
 * @returns {Promise<() => void>} - A function to unsubscribe from real-time updates when called.
 */
export async function fetchEventsForRange(
  start: Date,
  end: Date,
  department: Department,
  onEventsUpdate: (events: IEvent[]) => void
): Promise<() => void> {
  // Convert dates to 'YYYY-MM-DD' format for querying Firestore
  const startOfRangeString = start.toISOString().split("T")[0];
  const endOfRangeString = end.toISOString().split("T")[0];

  // Reference the events collection in Firestore
  const eventsCollectionRef = collection(db, "_events");

  // Create a query to fetch events where:
  // (startDate is within the range AND department matches) OR
  // (endDate is within the range AND department matches)
  const q = query(
    eventsCollectionRef,
    or(
      and(
        where("startDate", ">=", startOfRangeString),
        where("startDate", "<=", endOfRangeString),
        where("department", "in", [department, "all"])
      ),
      and(
        where("endDate", ">=", startOfRangeString),
        where("endDate", "<=", endOfRangeString),
        where("department", "in", [department, "all"])
      ),
      and(
        where("startDate", "<", startOfRangeString),
        where("endDate", ">", endOfRangeString),
        where("department", "in", [department, "all"])
      )
    )
  );

  /**
   * Processes a Firestore snapshot by converting documents to events and passing them to the update callback.
   *
   * @param {QuerySnapshot} snapshot - Firestore snapshot of documents matching the query.
   */
  const processSnapshot = async (snapshot: QuerySnapshot) => {
    // Convert Firestore documents to your IEvent objects
    const events = await convertDocsToEvents(snapshot.docs);
    // Trigger the callback to update the events
    onEventsUpdate(events);
  };

  // Fetch initial data when the function is called and process it
  const initialSnapshot = await getDocs(q);
  await processSnapshot(initialSnapshot);

  // Set up a real-time listener for changes to the queried events and process updates
  // Return the unsubscribe function to allow the caller to stop listening for updates
  return onSnapshot(q, processSnapshot);
}


/**
 * Converts Firestore documents to IEvent objects, filtering by department.
 *
 * This function processes all documents in parallel to improve performance.
 *
 * @param {any[]} docs - The Firestore documents to convert.
 * @returns {Promise<IEvent[]>} An array of IEvent objects.
 */
async function convertDocsToEvents(docs: any[]): Promise<IEvent[]> {
  // Map over the documents to create an array of Promises for event conversions
  const eventPromises: Promise<IEvent | null>[] = docs.map(async (doc) => {
    try {
      const docData = doc.data() as IFirestoreEvent;

      // Extract the department from the document data
      const eventDepartment: EventDepartment = docData.department;
      if (!eventDepartment) {
        console.warn("No department found for event", doc.id);
        return null; // Skip this event if department is missing
      }

      // Start fetching traveler and crew leader data concurrently
      const [traveler, crewLeader] = await Promise.all([
        getTravelerData(docData.traveler?.[0]),
        getEmployeeDetailsByRef(docData.crew?.[0]),
      ]);

      // Create the event object
      const event: IEvent = {
        id: doc.id,
        start: docData.startDate,
        end: docData.endDate,
        crew: crewLeader ?? null,
        department: eventDepartment,
        traveler: traveler ?? null,
        notes: docData.notes ?? null,
        customText: docData.customText ?? null,
        eventType: docData.eventType ?? null,
        resourceId: crewLeader?.preferredName || "unassigned",
        lastModifiedBy: docData.lastModifiedBy,
      };

      //update the search index for the event if it didn't exist
      if (!docData.searchIndexData || !docData.searchIndexData.subtitle) {
        try {
          const searchIndexData = getSearchIndexData(event);
          await setDoc(doc.ref, {searchIndexData}, {merge: true});
          console.log("Updated event search index data for event:", {id: doc.id, searchIndexData});
        } catch (error) {
          console.error("Error updating event search index data:", error);
        }
      }

      return event;
    } catch (error) {
      // Log the error and continue processing other documents
      console.error(`Error processing event ${doc.id}:`, error);
      return null; // Skip this event in case of an error
    }
  });

  // Wait for all event conversion Promises to resolve
  const events = await Promise.all(eventPromises);

  // Filter out any null results (events that were skipped)
  return events.filter((event): event is IEvent => event !== null);
}


/**
 * Retrieves traveler data from a DocumentReference.
 * @param travelerRef - The DocumentReference to the traveler.
 * @returns An ITraveler object or undefined if not found.
 */
async function getTravelerData(
  travelerRef?: DocumentReference
): Promise<ITraveler | undefined> {
  if (!travelerRef) {
    return undefined;
  }

  try {
    const travelerDoc = await getDoc(travelerRef);
    if (!travelerDoc.exists()) {
      console.error("Traveler document does not exist:", travelerRef.id);
      return undefined;
    }

    const data = travelerDoc.data() as IFirestoreTraveler;
    return {
      displayName: data.calendarDisplayName,
      workOrderType: data.workOrderType,
      department: data.thisTravelerIsFor,
      status: data.status,
      portalLink: data.portalLink, // Ensure portalLink is retrieved
      ref: travelerRef,
      id: travelerDoc.id,
    };
  } catch (error) {
    console.error("Error getting traveler data:", error);
    return undefined;
  }
}

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface SpinnerProps {
  className?: string;
}

/**
 * Component to display a spinner while loading data.
 * @constructor
 * @param {SpinnerProps} props - The properties for the Spinner component.
 */
const Spinner: React.FC<SpinnerProps> = ({className }: SpinnerProps) => {
  return (
    <div className={`d-flex flex-row align-items-center justify-content-center vh-100 ${className}`}>
      <div className="spinner-border text-warning" role="status"></div>
      <div className="ms-3 fs-4">Loading...</div>
    </div>
  );
};

export default Spinner;

/**
 * Interface representing the properties of a color.
 */
interface IColorProperties {
  /**
   * The background color of the event.
   * @type {string}
   */
  backgroundColor: string;
  /**
   * The text color of the event.
   * @type {string}
   */
  textColor: string;
}

/**
 * Interface representing a collection of event colors.
 */
type IEventColors = {
  [key in EventType]: IColorProperties;
};

/**
 * Type representing possible event types.
 * Each event type corresponds to a specific kind of event.
 */
export type EventType =
  'Inspections' | // Event type for inspections
  'Service Call' | // Event type for service calls
  'After Hours' | // Event type for after-hours events
  'Hard Date' | // Event type for hard date events
  'Normal' | // Event type for normal events
  'Stay-away' | // Event type for stay-away events
  'No PO (pending)' | // Event type for events with no PO (pending)
  'Pick-up / Delivery' | // Event type for pick-up or delivery events
  'Team Meeting' | // Event type for team meetings
  'Holiday' | // Event type for holidays
  'Day Off' | // Event type for days off
  'Other'; // Event type for other events

/**
 * A collection of predefined event colors.
 * Each key represents an event type, and the value is an object containing the background and text colors for that event type.
 */
export const eventColors: IEventColors = {
  "Inspections": {
    backgroundColor: "#b06003",
    textColor: "white",
  },
  "Service Call": {
    backgroundColor: "#fed66b",
    textColor: "black",
  },
  "After Hours": {
    backgroundColor: "#dd05a8",
    textColor: "white",
  },
  "Hard Date": {
    backgroundColor: "#ffb58e",
    textColor: "black",
  },
  "Normal": {
    backgroundColor: "#9ec7ff",
    textColor: "black",
  },
  "Stay-away": {
    backgroundColor: "#7d37ef",
    textColor: "white",
  },
  "No PO (pending)": {
    backgroundColor: "#e5eaf1",
    textColor: "black",
  },
  "Pick-up / Delivery": {
    backgroundColor: "#ffa7c0",
    textColor: "black",
  },
  "Team Meeting": {
    backgroundColor: "#CBC3E3",
    textColor: "black",
  },
  "Holiday": {
    backgroundColor: "#048a0f",
    textColor: "white",
  },
  "Day Off": {
    backgroundColor: "#9ae194",
    textColor: "black",
  },
  "Other": {
    backgroundColor: "#616671",
    textColor: "white",
  }
}

/**
 * Function to get the color properties for a given event type.
 * @param {string} [eventType] - The type of the event.
 * @returns {IColorProperties} The color properties for the event type.
 */
export function getColor(eventType: EventType | null | undefined): IColorProperties {
  const defaultColor: IColorProperties = {backgroundColor: 'gray', textColor: 'white'};

  if (!eventType) {
    return defaultColor;
  }

  try {
    const color = eventColors[eventType];
    return color ?? defaultColor;
  } catch {
    return defaultColor;
  }
}
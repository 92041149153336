/**
 * @file This file contains the main application component for the project.
 */
import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Sidebar from './components/Sidebar';
import Calendar from './components/Calendar';
import {ThemeProvider, useTheme} from './components/ThemeContext';
import './App.css';
import {Department, ITraveler} from './data/interfaces';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import {fetchUnscheduledTravelers} from './data/fetchTravelers';


/**
 * MainContent is a functional component that renders the main content of the application.
 * It includes a sidebar and a header, and manages the state for whether the sidebar is collapsed.
 * @returns {JSX.Element} The rendered component.
 */
const MainContent: React.FC = () => {

  const [currentDepartment, setCurrentDepartment] = useState<Department>('Installation');
  const [unscheduledTravelers, setUnscheduledTravelers] = useState<ITraveler[]>([]);
  const [selectedTravelerId, setSelectedTravelerId] = useState<string | undefined>(undefined);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const {theme} = useTheme();
  const {department, date} = useParams<{ department: string; date: string }>();

  /**
   * Toggles the state of the sidebar (collapsed/expanded).
   */
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    if (department) {
      const dept = department === 'customer-support' ? 'Customer Support' : 'Installation';
      setCurrentDepartment(dept as Department);
    } else {
      // Set a default department if none is provided
      setCurrentDepartment('Installation');
    }
  }, [department]);

// Fetch unscheduled travelers when the department changes
  useEffect(() => {
    const handleDataChange = (unscheduledTravelersData: ITraveler[]) => {

      const filteredTravelers = unscheduledTravelersData.filter((traveler) => {
        const isValid = traveler && (!traveler.events || traveler.events.length === 0) &&
          traveler.workOrderType != 'In-House Work';
        return isValid;
      });

      setUnscheduledTravelers(filteredTravelers);
    };

    const handleError = (error: Error) => {
      console.error('Error fetching unscheduled travelers:', error);
    };

    const unsubscribe = fetchUnscheduledTravelers(
      currentDepartment,
      handleDataChange,
      handleError
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentDepartment]);

  const handleTravelerClick = (travelerId: string) => {
    setSelectedTravelerId(travelerId);
  };

  return (
    <Row className="m-0 p-0 vh-100 min-vh-100">
      {/* Sidebar */}
      <Col
        xs="auto"
        md={isCollapsed ? 'auto' : 2}
        className={`p-0 d-none d-md-block overflow-y-auto h-100 ${isCollapsed ? 'collapsed' : ''}`}
      >
        <Sidebar
          isCollapsed={isCollapsed}
          department={currentDepartment}
          unscheduledTravelers={unscheduledTravelers}
          onTravelerClick={handleTravelerClick}
        />
      </Col>
      {/* Main Content */}
      <Col xs={isCollapsed ? 12 : 10} className="p-0">
        <div className={`content ${isCollapsed ? 'expanded' : ''} ps-3 `}>
          <Container fluid className="p-0 ">
            <Calendar
              isCollapsed={isCollapsed}
              toggleSidebar={toggleSidebar}
              currentDepartment={currentDepartment}
              setCurrentDepartment={setCurrentDepartment}
              initialDate={date}
              unscheduledTravelersCount={unscheduledTravelers.length}
              selectedTravelerId={selectedTravelerId}
              setSelectedTravelerId={setSelectedTravelerId}
            />
          </Container>
        </div>
      </Col>
    </Row>
  );
};

/**
 * App is the root component of the application.
 * It provides the ThemeContext to its children and renders the MainContent component.
 * @returns {JSX.Element} The rendered component.
 */
const App: React.FC = () => {
  return (
    <ThemeProvider>
      <Routes>
        <Route path="/:department/:date" element={<MainContent/>}/>
        <Route path="/:department" element={<MainContent/>}/>
        <Route path="/" element={<Navigate to="/installation/" replace/>}/>
      </Routes>
    </ThemeProvider>
  );
};

export default App;

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {onAuthStateChanged, signInWithCustomToken, User, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {auth} from "./firebaseConfig";
import {checkAuthStatus, monitorAuthState, onAuthSubdomain} from "./utils/authUtils";
import Spinner from "./components/Spinner";
import React, {useEffect, useState} from 'react';
import {UserProvider} from "./context/userContext";
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Button, Card, Col, Container} from "react-bootstrap";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/**
 * Root component that handles the authentication state and renders the main application component.
 * @constructor
 */
function RootComponent() {

    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function verifyAuth() {
            try {
                const token = await checkAuthStatus();
                await signInWithCustomToken(auth, token);
                setLoading(false);
            } catch (error) {
                console.log("error", error);
                setUser(null);
                setLoading(false);
            }
        }
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                if (onAuthSubdomain) { // call monitorAuthState each time the user is logged in
                    monitorAuthState(user);
                }
                // identify user with userGuiding
                // @ts-ignore
                window['userGuiding'].identify(user?.email, {
                    name: user?.displayName,
                    email: user?.email,
                    firstName: user?.displayName?.split(' ')[0],
                })
            } else {
                setUser(null);
            }
        });
        verifyAuth();
    }, []);

    const currentURL = window.location.href;

    /**
     * Signs in the user with Google authentication provider.
     */
    async function signInWithGoogle() {
        try {
            const provider = new GoogleAuthProvider();
            await signInWithPopup(auth, provider);
        } catch (e) {
            console.error("error signing in", e);
        }
    }
    if (!user) {
        return (
          <Container fluid className='text-center d-flex justify-content-center align-items-center min-vh-100 bg-body-secondary'>
              {loading
                ? <Spinner />
                : <Col className="d-flex justify-content-center align-items-center">
                    <Card className='d-inline-block mb-auto p-4 m-5' style={{ minWidth: '24rem', maxWidth: '30rem' }}>
                        <Card.Body>
                            <Card.Title>PWI Onsite Calendar</Card.Title>
                            <p className='text-secondary'>Please sign in to continue</p>
                            {onAuthSubdomain
                              ? <Button href={`https://auth.pwiworks.app/sign-in?redirect=${currentURL}`}>Sign In</Button>
                              : <Button onClick={signInWithGoogle}>Sign In</Button>}
                        </Card.Body>
                    </Card>
                </Col>
              }
          </Container>

        );
    }
    return (
      <App/>
    );
}

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <UserProvider>
              <RootComponent />
          </UserProvider>
      </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();

import {IEvent, ICalendarEvent} from "../data/interfaces";
import {buildEventTitle} from "./buildEventTitle";
import {getColor} from "./eventColors";

/**
 * Function to augment an event object for use in the calendar.
 * @param event
 */
export function augmentEventForCalendar(event: IEvent): ICalendarEvent {
  const color = getColor(event.eventType);

  return {
    ...event,
    end: adjustEndDateForCalendar(event.end),
    title: buildEventTitle(event),
    resourceId: event.crew?.preferredName || 'unassigned',
    backgroundColor: color.backgroundColor,
    textColor: color.textColor,
    borderColor: color.backgroundColor,
  };
}

/**
 * Function to adjust the end date of an event for use in the calendar.
 * @param endDate
 */
function adjustEndDateForCalendar(endDate: string | Date | undefined): string {
  if (!endDate) return '';
  const date = endDate instanceof Date ? endDate : new Date(endDate);
  date.setDate(date.getDate() + 1);
  return date.toISOString().split('T')[0];
}

/**
 * Function to adjust the end date of an event for use in Firestore.
 * @param endDate
 */
export function adjustEndDateForFirestore(endDate: string | Date | undefined): string {
  if (!endDate) return '';
  const date = endDate instanceof Date ? endDate : new Date(endDate);
  date.setDate(date.getDate() - 1);
  return date.toISOString().split('T')[0];
}

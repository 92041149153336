import React, {useState} from 'react';
import {Button, ButtonGroup, Dropdown, DropdownProps, InputGroup, Navbar, Form} from 'react-bootstrap';
import {BsChevronLeft, BsChevronRight, BsHammer, BsList, BsMoon, BsSun, BsWrench} from "react-icons/bs";
import {TbLayoutSidebarLeftExpand, TbLayoutSidebarRightExpand} from "react-icons/tb";
import {RiLogoutBoxLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import {SearchEvents} from "./SearchEvents";
import {MdCancel} from "react-icons/md";

interface HeaderProps {
  toggleSidebar: () => void;
  isCollapsed: boolean;
  handlePrev: () => void;
  handleToday: () => void;
  handleNext: () => void;
  jumpToDate: string;
  setJumpToDate: (date: string) => void;
  handleJumpToDate: () => void;
  handleNewEventClick: () => void;
  unscheduledTravelersCount: number;
  formattedDate: string;
  theme: string;
  toggleTheme: () => void;
  signOut: () => void;
}

const Header: React.FC<HeaderProps> = ({
                                         toggleSidebar,
                                         isCollapsed,
                                         handlePrev,
                                         handleToday,
                                         handleNext,
                                         jumpToDate,
                                         setJumpToDate,
                                         handleJumpToDate,
                                         handleNewEventClick,
                                         unscheduledTravelersCount,
                                         formattedDate,
                                         theme,
                                         toggleTheme,
                                         signOut
                                       }) => {
  //state variables
  const [searchQuery, setSearchQuery] = useState('');
  const suggestions = SearchEvents(searchQuery);

  /**
   * Custom toggle component for the dropdown
   */
  const CustomToggle = React.forwardRef<HTMLButtonElement, DropdownProps>(({children, onClick}, ref) => (
    <button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(e);
      }}
      className="btn btn-link"
    >
      {children}
    </button>
  ));

  const renderSuggestions = () => {
    if (searchQuery && suggestions.length === 0) {
      return (
        <ul
          className="dropdown-menu show p-2 border border-secondary text-black position-absolute top-100 end-0 overflow-y-auto max-vh-70 width-500px"
        >
          <li className="dropdown-item px-2 py-1 border-bottom border-secondary text-truncate">
            No results
          </li>
        </ul>
      );
    }

    if (suggestions.length === 0) {
      return null;
    }

    return (
      <ul
        className="dropdown-menu show p-2 border border-secondary text-black position-absolute top-100 end-0 overflow-y-auto max-vh-70 width-500px"
      >
        {suggestions.map((hit, index) => {
          const { searchIndexData, formattedDate } = hit;
          const { title, subtitle, dateRange } = searchIndexData || {};

          // Access highlighted values
          const highlightedTitle = hit._highlightResult?.searchIndexData?.title?.value || title;
          const highlightedSubtitle = hit._highlightResult?.searchIndexData?.subtitle?.value || subtitle;
          const highlightedDateRange = hit._highlightResult?.searchIndexData?.dateRange?.value || dateRange;

          let linkPath = '#'; // Default link
          const encodedDate = encodeURIComponent(formattedDate);
          let department = '';
          if (subtitle?.startsWith('Installation')) {
            linkPath = `/installation/${encodedDate}`;
          } else if (subtitle?.startsWith('Customer Support')) {
            linkPath = `/customer-support/${encodedDate}`;
          }

          return (
            <li
              key={index}
              className="dropdown-item px-2 py-1 border-bottom border-secondary text-truncate"
            >
              {title ? (
                <Link
                  to={linkPath}
                  className="text-decoration-none text-black d-block text-truncate"
                >
                <span
                  className="fw-bold text-truncate"
                  dangerouslySetInnerHTML={{ __html: highlightedTitle }}
                />
                  <div className="d-flex justify-content-between align-items-center">
                  <span
                    className="text-truncate"
                    dangerouslySetInnerHTML={{ __html: highlightedSubtitle }}
                  />
                    <span
                      className="text-muted text-truncate"
                      dangerouslySetInnerHTML={{__html: highlightedDateRange}}
                    />
                  </div>
                </Link>
              ) : (
                'No title available'
              )}
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <Navbar className={`navbar bg-white ${isCollapsed ? 'ms-3' : 'ms-4'} transition`}>
      <div className="d-flex align-items-center">
        <Button
          onClick={toggleSidebar}
          type="button"
          variant="primary"
          className="d-none d-md-inline-flex me-2 align-items-center"
        >
          {isCollapsed ? <TbLayoutSidebarLeftExpand size={22}/> : <TbLayoutSidebarRightExpand size={22}/>}
          <span className="badge bg-danger ms-2 p-1">{unscheduledTravelersCount}</span>
        </Button>
        <ButtonGroup className="me-2">
          <Button variant="secondary" onClick={handlePrev}>
            <BsChevronLeft/>
          </Button>
          <Button variant="secondary" onClick={handleToday} className="px-3">
            Today
          </Button>
          <Button variant="secondary" onClick={handleNext}>
            <BsChevronRight/>
          </Button>
        </ButtonGroup>

        {/* Date Input and Go Button */}
        <InputGroup className="me-2 d-none d-md-inline-flex">
          <Form.Control
            type="date"
            value={jumpToDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setJumpToDate(e.target.value)}
            className="border-secondary rounded-end-0"
          />
          <Button variant="secondary" onClick={handleJumpToDate}>
            Go
          </Button>
        </InputGroup>

        <Button variant="success" onClick={handleNewEventClick} className="d-none d-md-inline-flex text-nowrap">
          New Event
        </Button>
      </div>
      <div className="d-flex align-items-end ms-auto">
        {/*SearchBar div*/}
        <div id="searchbox" className="d-flex flex-column position-relative me-3 w-100">
          <div className="d-flex">
            <input
              type="text"
              placeholder="Search events"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="form-control border border-secondary"
            />
            {searchQuery && (
              <button
                type="button"
                className="btn position-absolute top-50 end-0 translate-middle-y pe-2 "
                onClick={() => setSearchQuery('')} // Clear the search input
                aria-label="Clear search"
              >
                <MdCancel/> {/* Use the MdCancel icon */}
              </button>
            )}
          </div>
          {renderSuggestions()}
        </div>
        {/*Dropdown div*/}
        <div className="d-flex align-items-center">
          <Dropdown align="end" drop="down">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <BsList size={30}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {theme === 'light' && (
                <Dropdown.Item onClick={toggleTheme}>
                  <BsMoon/> Use Dark Mode
                </Dropdown.Item>
              )}
              {theme === 'dark' && (
                <Dropdown.Item onClick={toggleTheme}>
                  <BsSun/> Use Light Mode
                </Dropdown.Item>
              )}
              <Dropdown.Item as={Link} to={`/installation/${formattedDate}`}>
                <BsHammer/> Installation
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`/customer-support/${formattedDate}`}>
                <BsWrench/> Customer Support
              </Dropdown.Item>
              <Dropdown.Item onClick={() => signOut()}>
                <RiLogoutBoxLine/> Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Navbar>
  );
};

export default Header;

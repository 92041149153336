import {IEvent} from "../data/interfaces";


/**
 * Builds the event title based on the IEvent object.
 * @param event - The event object containing crew, traveler, custom text, and other event properties.
 * @returns The constructed title for the event.
 */
export function buildEventTitle(event: IEvent): string {
  let titleIcons: string[] = [];

  // Add icons based on crew
  switch (event.eventType) {
    case 'Holiday':
      titleIcons.push("🎄");
      break;
    case 'Day Off':
      titleIcons.push("🏖️");
      break;
    case 'Team Meeting':
      titleIcons.push("🗣️");
      break;
    case 'No PO (pending)':
      titleIcons.push("🛒");
      break;
    default:
      // no special icons for other event types
      break;
  }

  if (event.notes) {
    titleIcons.push("📝");
  }

  let titleParts: string[] = [];

  // Add traveler display name
  if (event.traveler) {
    titleParts.push(event.traveler.displayName);
  }

  // Add custom text if available
  if (event.customText) {
    titleParts.push(event.customText);
  }

  // Join icons and parts to create the final title
  const title = `${titleIcons.join("")} ${titleParts.join(" - ")}`.trim();
  return title.length > 0 ? title : "(untitled)";
}

import React from 'react';
import UnscheduledTravelers from './UnscheduledTravelers';
import {useTheme} from "./ThemeContext";
import {Department, ITraveler} from "../data/interfaces";


interface SidebarProps {
  isCollapsed: boolean;
  department: Department;
  unscheduledTravelers: ITraveler[];
  onTravelerClick: (travelerId: string) => void;
}

/**
 * Sidebar component to display unscheduled travelers.
 * @param isCollapsed
 * @constructor
 */

const Sidebar: React.FC<SidebarProps> = ({isCollapsed, department, onTravelerClick}) => {
  const {theme} = useTheme();


  return (
    <>
      <div
        className={`min-vh-100 h-100 w-100 ${isCollapsed ? 'd-none' : 'd-block'} ${
          theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'
        } overflow-auto`}
        style={{height: '100vh'}}
      >
        <div className="mt-2 text-center">
          <h3>Unscheduled Travelers</h3>
        </div>
        <div>
           <UnscheduledTravelers
        department={department}
        onTravelerClick={onTravelerClick}
      />
        </div>
      </div>
    </>
  );
};

export default Sidebar;

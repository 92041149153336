import {IEvent, IFirestoreEvent} from "./interfaces";
import {doc, setDoc, Timestamp} from 'firebase/firestore';
import {db} from '../firebaseConfig';
import {User} from "firebase/auth";
import {getSearchIndexData} from "../utils/getSearchIndexData";

/**
 * Saves an event to Firestore.
 *
 * @param {IEvent} eventDetails - The details of the event to be saved.
 * @param {User} user - The user who is saving the event.
 * @returns {Promise<void>} A promise that resolves when the event is saved.
 */
export async function saveEvent(eventDetails: IEvent, user: User | null): Promise<void> {

  /**
   * Convert the start and end dates to ISO strings if they are Date objects.
   */
  const startDate = eventDetails.start instanceof Date ? eventDetails.start.toISOString() : eventDetails.start;
  const endDate = eventDetails.end instanceof Date ? eventDetails.end.toISOString() : eventDetails.end;

  /**
   * Format the event details to match the Firestore schema.
   */
  const formattedEventDetails: IFirestoreEvent = {
    id: eventDetails.id,
    startDate,
    endDate: endDate ?? startDate,
    department: eventDetails.department,
    crew: eventDetails.crew ? [eventDetails.crew.ref] : [],
    notes: eventDetails.notes ?? null,
    customText: eventDetails.customText ?? null,
    eventType: eventDetails.eventType ?? null,
    traveler: eventDetails.traveler ? [eventDetails.traveler.ref] : [],
    lastModifiedBy: {
      id: user?.uid ?? 'unknown',
      time: Timestamp.now()
    },
    searchIndexData: getSearchIndexData(eventDetails)
  };

  try {
    const eventRef = doc(db, '_events', formattedEventDetails.id);
    await setDoc(eventRef, formattedEventDetails, {merge: true});
  } catch (error) {
    console.error('Error saving event to Firebase:', error);
  }
}
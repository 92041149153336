import {DocumentReference, getDoc} from "firebase/firestore";
import {IEmployee, IFirestoreEmployee} from "./interfaces";

/**
 * Retrieves employee details from a DocumentReference.
 * @param employeeRef - The DocumentReference to the crew member.
 * @returns An IEmployee object or undefined if not found.
 */
export async function getEmployeeDetailsByRef(
  employeeRef?: DocumentReference
): Promise<IEmployee | undefined> {
  if (!employeeRef) {
    return undefined;
  }

  try {
    const employeeDoc = await getDoc(employeeRef);
    if (!employeeDoc.exists()) {
      console.error("Crew document does not exist:", employeeRef.id);
      return undefined;
    }

    const data = employeeDoc.data() as IFirestoreEmployee;
    return {
      ...data,
      id: employeeDoc.id,
      ref: employeeRef,
    };
  } catch (error) {
    console.error("Error getting crew data:", error);
    return undefined;
  }
}